<template>
  <div class="empty-layout">
    <!-- <header></header> -->
    <main>
      <router-view />
    </main>
    <!-- <footer></footer> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.empty-layout {
  padding: 20px;
  height: 100vh;
}
</style>
